export const headerText = 'W moim gabinecie pomogę Ci uporać się między innymi z takimi przypadłościami jak:'
export const diseasesList = [
 {
  title: 'Odcisk',
  description: 'jest to reakcja obronna skóry na stały ucisk, w centralnej części znajduje się twardy rdzeń, który wnika w żywą warstwę skóry powodując ból na skutek drażnienia zakończeń nerwowych'
 },
 {
  title: 'Modzel',
  description: 'jest to piekąca, plackowatego kształtu, dosyć miękka, o żółtym zabarwieniu narośl na skórze stóp, spowodowana wzmożonym uciskiem lub tarciem'
 },
 {
  title: 'Grzybica paznokci',
  description: 'objawia się najczęściej kruchością, łamliwością, rozwarstwieniem oraz przebarwieniem płytki paznokcia na żółtawy, szarobrunatny czy zielonkawy kolor. Może wydzielać specyficzny zapach. Nie powoduje dolegliwości bólowych'
 },
 {
  title: 'Grzybica stóp',
  description: 'wywoływane są przez różne rodzaje grzybów, ale najczęściej są to zakażenia dermatofitami. Objawia się zaczerwienieniem skóry, świądem, drobnymi pęcherzykami oraz złuszczaniem naskórka'
 },
 {
  title: 'Zespół stopy cukrzycowej',
  description: 'jest jednym z najgroźniejszych powikłań cukrzycy. ZSC definiowany jest jako zespół współistniejących objawów obejmujących infekcję, owrzodzenie lub destrukcję tkanek głębokich wraz z niedostatecznym ukrwieniem lub unerwieniem stopy'
 },
 {
  title: 'Wrastające paznokcie',
  description: 'jest to nadmierny wzrost bocznych stron płytki paznokcia powodujących ból oraz stan zapalny skóry otaczającej paznokieć. Wrastanie paznokci spowodowane jest najczęściej nieodpowiednim skracaniem płytki paznokciowej, źle dobranym, zbyt wąskim obuwiem, nadwagą, ciążą, mogą to być również predyspozycje genetyczne'
 },
 {
  title: 'Wkręcające się paznokcie',
  description: 'wkręcające się paznokcie charakteryzują się tym, że mają kształt rurkowaty. Zakręcające boki paznokci ściskają łożysko i mogą powodować ból'
 },
 {
  title: 'Nadpotliwość stóp',
  description: 'jest to dosyć uciążliwa i nieprzyjemna dolegliwość, która spowodowana jest nadmierną pracą gruczołów potowych i zwiększonej ilości wydzielanego potu. Zjawisko to sprzyja rozwojowi bakterii, czego efektem jest przykry zapach'
 },
 {
  title: 'Pękające pięty',
  description: 'szczelinowate pęknięcia na skórze pięt. W przypadku głębszych pęknięć może dochodzić do krwawienia i związanych  z tym dolegliwości bólowych oraz infekcji'
 },
 {
  title: 'Onycholiza',
  description: 'jest to oddzielanie się płytki paznokciowej od łożyska. Powodem mogą być genetyczne predyspozycje, nadmierny kontakt ze środkami chemicznymi, wodą, nieodpowiednie obuwie (zbyt ciasne i wąskie)'
 },
 {
  title: 'Paznokcie szponiaste',
  description: 'zmiany dotyczą osób w różnym wieku. Polegają na zmianie kierunku wzrostu paznokci. Mogą być spowodowane długotrwałym uciskiem w niewygodnym obuwiu, nadmierne rogowacenie łożyska, tendencje wrodzone'
 },
 {
  title: 'Brodawki wirusowe',
  description: 'potocznie zwane kurzajkami, wywołane są wniknięciem do skóry wirusa brodawczaka ludzkiego HPV. Najczęściej atakuje osoby o obniżonej odporności organizmu. Brodawki wirusowe są zakaźne'
 },
 {
  title: 'Krwiak podpaznokciowy',
  description: 'powstaje w następstwie urazu paznokcia i naczyń położonych pod płytką paznokcia'
 },
]