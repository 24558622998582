import React from 'react'
import styled from 'styled-components'
import { color5, color7, color12, } from '../../constants/colors'
import { headerText, contentText } from '../../content/aboutPodologyText'
import { map } from 'ramda'
import MaxWidthContainer from '../Layout/MaxWidthContainer'

const Header = styled.div`
 color: ${color12};
 background-color: ${color7};
 height: 55px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 @media(min-width: 600px){
 height: 95px;
 }
 @media(min-width: 1024px){
 height: 115px;
 }
`

const HeaderText = styled.p`
 margin: 0 auto 0 0;
 text-align: left;
 font-family: Roboto, sans-serif;
 font-weight: 500;
 font-size: 12px;
 line-height: 140%;
 @media(min-width: 600px){
 font-size: 20px;
 text-align: center;
 }
 @media(min-width: 1024px){
 font-size: 27px;
 }
`

const ContentWrapper = styled.section`
 background-color: ${color5};
 padding-top: 10px;
 padding-bottom: 5px;
`
const ContentText = styled.p`
 margin: 0 auto 10px;
 text-align: justify;
 font-family: Roboto, sans-serif;
 font-size: 9px;
 line-height: 140%;
 @media(min-width: 600px){
  font-size: 12px;
  margin: 5px auto 10px;
 }
 @media(min-width: 1024px){
  font-size: 20px;
  margin: 5px auto 10px;
 }
`

const renderHeaderText = () => map(p =>
 <HeaderText>{p}</HeaderText>
 , headerText)

const renderContentText = () => map(p =>
 <ContentText>{p}</ContentText>
 , contentText)


const AboutPodology = () => {
 return (
  <>
   <Header>
    <MaxWidthContainer>
     {renderHeaderText()}
    </MaxWidthContainer>
   </Header>
   <ContentWrapper>
    <MaxWidthContainer>
     {renderContentText()}
    </MaxWidthContainer>
   </ContentWrapper>
  </>
 )
}

export default AboutPodology
