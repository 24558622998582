import React, { useState } from 'react';
import { Link } from 'gatsby';

import Layout from "../components/Layout"

import AboutPodology from '../components/PatientZone/AboutPodology'
import Diseases from '../components/PatientZone/Diseases'
import Contact from "../components/HomePage/Contact"
import SEO from '../components/Seo';

const IndexPage = () => {
 return (
  <Layout >
   <SEO title="Strefa pacjenta" />
   <AboutPodology />
   <Diseases />
   <Contact />
  </Layout>
 );
}

export default IndexPage;
