import React from 'react'
import styled from 'styled-components'
import { map, addIndex } from 'ramda'
import { color5, color7, color12, } from '../../constants/colors'
import { headerText, diseasesList } from '../../content/diseasesText'
import MaxWidthContainer from '../Layout/MaxWidthContainer'

const DiseasesHeader = styled.h6`
 background-color: ${color7};
 margin: 0;
 padding: 12px 0;
 font-family: Roboto, sans-serif;
 font-size: 10px;
 color: ${color12}
 @media(min-width: 600px){
  padding: 20px 0;
  font-size: 13px;
 }
 @media(min-width: 1024px){
  padding: 30px 0 20px 0;
  font-size: 20px;
 }
`

const DiseasesList = styled.ul`
 list-style: none;
 padding: 0;
 margin: 0;
`

const DiseasesListItem = styled.li`
 background-color: ${props => props.lighter ? color7 : color5};
 padding: 9px 0;
 @media(min-width: 600px){
  padding: 12px 0;
 }
 @media(min-width: 1024px){
  padding: 25px 0;
 }
`
const DiseaseTitle = styled.h6`
 display: inline;
 margin: 0;
 font-family: Roboto, sans-serif;
 font-weight: 700;
 font-size: 10px;
 color: ${color12};
 @media(min-width: 600px){
  font-size: 13px;
 }
 @media(min-width: 1024px){
  font-size: 20px;
 }
`
const DiseaseDescription = styled.p`
 display: inline;
 margin: 0;
 font-family: Roboto, sans-serif;
 font-size: 10px;
 color: ${color12}
 @media(min-width: 600px){
  font-size: 13px;
 }
 @media(min-width: 1024px){
  font-size: 20px;
 }
`

const mapIndexed = addIndex(map)

const renderDiseasesList = () => mapIndexed((disease, idx) => {
 let lighter;
 if ((((idx / 3) % 2) % 2) < 1) {
  lighter = true
 }
 else {
  lighter = false
 }

 return (
  <DiseasesListItem key={disease.title} lighter={lighter}>
   <MaxWidthContainer>
    <DiseaseTitle>{disease.title} - </DiseaseTitle><DiseaseDescription>{disease.description}</DiseaseDescription>
   </MaxWidthContainer>
  </DiseasesListItem>
 )
}
 , diseasesList)

const Diseases = () => {
 return (
  <>
   <DiseasesHeader>
    <MaxWidthContainer>
     {headerText}
    </MaxWidthContainer>
   </DiseasesHeader>
   <DiseasesList>
    {renderDiseasesList()}
   </DiseasesList>
  </>
 )
}

export default Diseases
